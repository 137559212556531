import {Screen} from '../Screen'
import styles from '../Screen.module.css'
import {SkillSquare} from '../SkillSquare'
import particuliers from '../../images/icons/fiscalite_particuliers.svg'
import entreprise from '../../images/icons/conseil_entreprises.svg'
import crypto from '../../images/icons/crypto.svg'
import contentieux from '../../images/icons/contentieux.svg'
import React, {useMemo, useState} from 'react'
import {useScreenDimensions} from '../../hooks/useScreenDimensions'

interface ISkill {
  title: string
  iconUrl: string
  paragraphs: string[]
}

const skills: ISkill[] = [
  {
    title: 'Fiscalité des particuliers et des professionnels',
    iconUrl: particuliers,
    paragraphs: [
      'J’accompagne les contribuables face aux différents sujets fiscaux qu’ils rencontrent dans leur vie.',
      'En amont de la réalisation d’un projet, j’interviens pour sécuriser les impacts fiscaux et les traiter de la manière la plus efficiente possible.',
      'Au stade du contrôle fiscal, je représente au mieux les intérêts du contribuable face à l’administration fiscale. Si le désaccord avec l’administration persiste, je défends les intérêts du contribuable devant les juridictions administratives et judiciaires.'
    ]
  },
  {
    title: 'Conseil d’entreprises',
    iconUrl: entreprise,
    paragraphs: [
      'Les entreprises sont confrontées à des problématiques variées dès leur création et à l’occasion de nombreux événements, notamment en cas d’entrée au capital d’un nouvel associé ou de vente.',
      'Je suis aux côtés des petites et moyennes entreprises pour les orienter au mieux face aux différents sujets qu’elles rencontrent.'
    ]
  },
  {
    title: 'Fiscalité des crypto-actifs',
    iconUrl: crypto,
    paragraphs: [
      'Les crypto-actifs ayant connu un essor tout particulier ces dernières années, j’ai choisi de mettre à profit mes connaissances fiscales au service d’un domaine technologique que j’apprécie particulièrement.',
      'La technologie blockchain et les actifs numériques sur lesquels elle repose étant nouveaux, le droit et particulièrement la fiscalité relative à ces actifs est encore en construction.',
      'Je suis à votre disposition pour analyser les problématiques qui vous concernent et vous accompagner pour sécuriser les impacts fiscaux générés par votre activité.'
    ]
  },
  {
    title: 'Contentieux',
    iconUrl: contentieux,
    paragraphs: [
      'J’interviens devant les différentes juridictions pour représenter les intérêts de mes clients dans des contentieux de toutes natures, particulièrement civils et commerciaux.',
      'Lorsque les litiges peuvent être résolus différemment, j’assiste mes clients en vue de parvenir à un règlement amiable ou négocié.'
    ]
  }
]

export const SkillsScreen = ({skillsRef}: { skillsRef?: React.RefObject<HTMLInputElement> }) => {
  const {isDesktop} = useScreenDimensions()

  const initialSkillTitle = isDesktop ? '' : skills[0].title
  const [selectedSkill, updateSelectedSkill] = useState(initialSkillTitle)
  const selectedParagraphs = useMemo(() => {
    const selectedSkillObject = skills.find(skill => skill.title === selectedSkill)
    return selectedSkillObject && selectedSkillObject.paragraphs
  }, [selectedSkill])


  return (
    <Screen screenRef={skillsRef}>
      <div className={styles.leftCenteredText}>
        <h1>Domaines d'intervention</h1>
        <div className={styles.grenatLine}/>
      </div>
      <div className={styles.innerCenteredContainer}>
        {skills.map((skill, index) => (
          <React.Fragment key={skill.title}>
            <SkillSquare
              textIsOnRight={index % 2 === 0}
              title={skill.title}
              iconUrl={skill.iconUrl}
              paragraphs={skill.paragraphs}
              onClick={() => {
                if (isDesktop) return
                if (selectedSkill === skill.title) {
                  updateSelectedSkill('')
                } else {
                  updateSelectedSkill(skill.title)
                }
              }}
            />
            {!isDesktop && selectedSkill !== '' && selectedSkill === skill.title && selectedParagraphs &&
            <>
              {selectedParagraphs.map(paragraph => (
                <p key={paragraph.slice(0, 15)}>{paragraph}</p>
              ))}
            </>
            }
          </React.Fragment>
        ))}
      </div>
    </Screen>
  )
}
