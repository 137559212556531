import {Screen} from '../Screen'
import styles from '../Screen.module.css'
import aboutPicture from '../../images/thomas-schauber-transparent.png'
import React from 'react'

export const AboutScreen = ({aboutRef}: { aboutRef?: React.RefObject<HTMLInputElement> }) => {
  return (
    <Screen screenRef={aboutRef}>
      <div className={styles.leftCenteredText}>
        <h1>À propos</h1>
        <div className={styles.grenatLine}/>
      </div>

      <div className={styles.aboutWrapper}>
        <div
          className={styles.aboutPicture}
          style={{backgroundImage: `url(${aboutPicture})`}}
        />
        <div className={styles.aboutText}>
          <p>
            Je suis diplômé d’un Master en procédure fiscale, d’un Diplôme de Juriste Conseil d’Entreprise (DJCE) ainsi que d’un Certificat d’Etudes Spécialisées en droit fiscal.
          </p>
          <p>
            Après avoir obtenu le Certificat d’Aptitude à la Profession d’Avocat, j’ai exercé en tant qu’Avocat fiscaliste au sein du cabinet CMS Francis Lefebvre Avocats pendant plus de deux années.
          </p>
          <p>
            J’ai fait le choix de m’installer à titre individuel pour être en mesure d’accompagner une clientèle variée sur des sujets innovants comme les problématiques fiscales liées aux activités numériques ou aux crypto-actifs.
          </p>
          <p>
            J’interviens auprès d’une clientèle de particuliers comme de professionnels, principalement en matière fiscale, mais plus généralement dans l’ensemble des domaines intéressant le droit des affaires et le contentieux.
          </p>
          <p>
            Je dispose de la rigueur des grands cabinets d’affaires que j’allie à une fine connaissance des sujets actuels et pratiques auxquels sont confrontés mes clients.
          </p>
          <p>
            Je m’efforce de leur proposer un travail à forte valeur ajoutée, qui leur permet d’anticiper ou de solutionner les problématiques qu’ils rencontrent.
          </p>
          <p>
            J’interviens sur l’ensemble de la France en matière de conseil, de contrôle fiscal et de contentieux, en présentiel comme à distance.
          </p>
          <p>
            Je suis à votre entière disposition pour que nous évoquions ensemble les sujets qui vous préoccupent.
          </p>
        </div>
      </div>
    </Screen>
  )
}
