import styles from './SkillSquare.module.css'
import React, {useState} from 'react'
import {useScreenDimensions} from '../hooks/useScreenDimensions'

interface ISkillSquareProps {
  textIsOnRight: boolean;
  iconUrl: string;
  title: string;
  paragraphs: string[];
  onClick?: () => void;
}

interface ITextAndLogoProps {
  isDesktop: boolean | undefined;
  onClick?: () => void;
  iconUrl: string;
  title: string;
  tiltElement: boolean;
}

const TitleAndLogo = ({title, iconUrl, isDesktop, onClick, tiltElement}: ITextAndLogoProps) => {
  return (
    <div
      className={styles.container}
      onClick={() => onClick && onClick()}
    >
      <div style={{position: 'relative'}}>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${iconUrl})`,
            // transform: tiltElement ? 'rotate3d(1, 1, 0, 90deg)' : 'rotate(0deg)',
            opacity: tiltElement ? '1' : '1'
          }}
        />
        {/*<div*/}
        {/*  className={styles.image}*/}
        {/*  style={{*/}
        {/*    position: 'absolute',*/}
        {/*    backgroundImage: `url(${iconUrl})`,*/}
        {/*    transition: 'opacity 1s ease-in',*/}
        {/*    opacity: tiltElement ? '1' : '1',*/}
        {/*    top: '0',*/}
        {/*    left: '0'*/}
        {/*  }}*/}
        {/*/>*/}
      </div>
      {!isDesktop && <h2>{title}</h2>}
    </div>
  )
}

export const SkillSquare = ({textIsOnRight, iconUrl, title, paragraphs, onClick}: ISkillSquareProps) => {
  const {isDesktop} = useScreenDimensions()
  const [tiltElement, setTiltElement] = useState(false)

  return (
    <div
      className={styles.mainContainer}
      style={{margin: '2rem 0'}}
      onMouseOver={() => setTiltElement(true)}
      onMouseOut={() => setTiltElement(false)}
    >
      {textIsOnRight &&
      <TitleAndLogo isDesktop={isDesktop} iconUrl={iconUrl} title={title} onClick={onClick} tiltElement={tiltElement}/>
      }
      {isDesktop === true &&
      <div className={styles.textContainer} style={{textAlign: textIsOnRight ? 'left' : 'right'}}>
        <h2>{title}</h2>
        {paragraphs.map(paragraph => (
          <p key={paragraph.slice(0, 15)}>{paragraph}</p>
        ))}
      </div>
      }
      {!textIsOnRight &&
      <TitleAndLogo isDesktop={isDesktop} iconUrl={iconUrl} title={title} onClick={onClick} tiltElement={tiltElement}/>
      }
    </div>
  )
}
