import {useEffect, useState} from 'react'

// getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
const TABLET_BREAKPOINT = 768
const DESKTOP_BREAKPOINT = 1200

interface WindowDimensions {
  width: number | undefined
  height: number | undefined
  isLandscape: boolean | undefined
  isMobile: boolean | undefined
  isTablet: boolean | undefined
  isDesktop: boolean | undefined
}

export const useScreenDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    width: undefined,
    height: undefined,
    isLandscape: undefined,
    isMobile: undefined,
    isTablet: undefined,
    isDesktop: undefined
  })
  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        isLandscape: window.innerWidth > window.innerHeight,
        isMobile: window.innerWidth < TABLET_BREAKPOINT,
        isTablet: window.innerWidth < DESKTOP_BREAKPOINT && window.innerWidth >= TABLET_BREAKPOINT,
        isDesktop: window.innerWidth >= DESKTOP_BREAKPOINT
      })
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return (): void => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
