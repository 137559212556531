import { Screen } from '../Screen'
import styles from '../Screen.module.css'
import mobile from '../../images/icons/mobile.svg'
import email from '../../images/icons/email.svg'
import telegram from '../../images/icons/telegram.svg'
import localisation from '../../images/icons/localisation.svg'
import linkedin from '../../images/icons/linkedin.svg'
import React, { useMemo } from 'react'
import { useScreenDimensions } from '../../hooks/useScreenDimensions'

const EMAIL = 'thomas.schauber@ts-avocats.fr'
const PHONE_NUMBER = '+33 3 87 79 05 53'

export const ContactScreen = ({ contactRef }: { contactRef?: React.RefObject<HTMLInputElement> }) => {
  const { width } = useScreenDimensions()
  const containerWidth = useMemo(() => {
    return width && contactRef && contactRef.current && contactRef.current.clientWidth ? `${contactRef.current.clientWidth}px` : undefined
  }, [contactRef, width])

  return (
    <Screen screenRef={contactRef}>
      <div className={styles.leftCenteredText}>
        <h1>Contact</h1>
        <div className={styles.grenatLine} />
      </div>

      <div className={styles.contactIconsContainer}>
        <div className={styles.contactLine}>
          <img src={mobile} alt={'mobile-phone'} />
          <a href={`tel:${PHONE_NUMBER.replaceAll(' ', '')}`}>
            <p>{PHONE_NUMBER}</p>
          </a>
        </div>

        <div className={styles.contactLine}>
          <img src={localisation} alt={'mobile'} />
          <a href={'https://goo.gl/maps/C5nyzMmP9H6sFdCh9'} target={'_blank'} rel={'noreferrer'}>
            <p>9 B, rue Poncelet - 57000 Metz</p>
          </a>
        </div>

        <div className={styles.contactLine}>
          <img src={email} alt={'email'} />
          <a href={`mailto:${EMAIL}`}>
            <p>{EMAIL}</p>
          </a>
        </div>

        <div className={styles.contactLine}>
          <img src={telegram} alt={'telegram'} />
          <p>@Thomas_Schauber</p>
        </div>

        <div className={styles.contactLine}>
          <img src={linkedin} alt={'mobile'} />
          <a href={'https://www.linkedin.com/in/thomas-schauber-45745638/'} target={'_blank'} rel={'noreferrer'}>
            <p>LinkedIn</p>
          </a>
        </div>
      </div>
      {/*{containerWidth &&*/}
      {/*<LeafletMap*/}
      {/*  mapWidth={containerWidth}*/}
      {/*/>*/}
      {/*}*/}
    </Screen>
  )
}
