import styles from './Screen.module.css'
import hexagone from '../images/hexagone.svg'
import React from 'react'

interface IScreenProps {
  screenRef?: React.RefObject<HTMLInputElement>;
  children?: React.ReactNode;
}

export const Screen = ({screenRef, children}: IScreenProps) => {
  return (
    <div ref={screenRef} className={styles.container}>
      {children}
      <div className={styles.innerCenteredContainer}>
        <img className={styles.hexagon} src={hexagone} alt={'hexagone'}/>
      </div>
    </div>
  )
}
