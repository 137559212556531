import { IntroScreen } from './components/screens/IntroScreen'
import { AboutScreen } from './components/screens/AboutScreen'
import { SkillsScreen } from './components/screens/SkillsScreen'
import { ContactScreen } from './components/screens/ContactScreen'
import { Footer } from './components/Footer'
import React, { useRef } from 'react'
import background from './images/bg_pattern.svg'

export const scrollToElement = (ref: React.RefObject<HTMLInputElement>) => {
  if (!ref || !ref.current) return
  return ref.current.scrollIntoView()
}

function App() {
  const aboutRef = useRef(null)
  const skillsRef = useRef(null)
  const contactRef = useRef(null)

  return (
    <>
      <main style={{ backgroundImage: `url(${background})` }}>
        <IntroScreen
          aboutRef={aboutRef}
          skillsRef={skillsRef}
          contactRef={contactRef}
        />
        <SkillsScreen skillsRef={skillsRef} />
        <AboutScreen aboutRef={aboutRef} />
        <ContactScreen contactRef={contactRef} />
      </main>
      <Footer />
    </>
  )
}

export default App
