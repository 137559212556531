import { Screen } from '../Screen'
import { Button } from '../Button'
import styles from '../Screen.module.css'
import logo from '../../images/logo.svg'
import React from 'react'
import { scrollToElement } from '../../App'

interface IIntroScreenProps {
  aboutRef?: React.RefObject<HTMLInputElement>
  skillsRef?: React.RefObject<HTMLInputElement>
  contactRef?: React.RefObject<HTMLInputElement>
}

export const IntroScreen = ({ aboutRef, skillsRef, contactRef }: IIntroScreenProps) => {
  return (
    <Screen>
      <div className={styles.centeredFlexText}>
        <img className={styles.introLogo} src={logo} alt={'logo'} />
        <h1>Thomas Schauber</h1>
        <div className={styles.grenatLine} />
        <h1>Avocat</h1>
      </div>

      <div className={styles.innerCenteredContainer}>
        <Button
          title={`Domaines d'intervention`}
          onClickCallback={() => skillsRef && scrollToElement(skillsRef)}
        />
        <Button
          title={'À propos'}
          onClickCallback={() => aboutRef && scrollToElement(aboutRef)}
        />
        {/*<a href={'https://ts-avocats.fr/blog/'} target={'_blank'} rel={'noreferrer'}>*/}
        {/*  <Button*/}
        {/*    title={'Blog'}*/}
        {/*  />*/}
        {/*</a>*/}
        <Button
          title={'Contact'}
          onClickCallback={() => contactRef && scrollToElement(contactRef)}
        />
      </div>

    </Screen>
  )
}
