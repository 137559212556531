import logoWhite from '../images/logo_white.svg'
import linkedin from '../images/icons/linkedin.svg'
import wordpress from '../images/icons/wordpress.svg'

export const Footer = () => {
  return (
    <footer>
      {/*<div>*/}
      {/*  <a href={'https://www.linkedin.com/in/thomas-schauber-45745638/'} target={'_blank'} rel={'noreferrer'}>*/}
      {/*    <img src={linkedin} alt={'linkedin'}/>*/}
      {/*  </a>*/}
      {/*  <a href={'https://ts-avocats.fr/blog/'} target={'_blank'} rel={'noreferrer'}>*/}
      {/*    <img src={wordpress} alt={'wordpress'}/>*/}
      {/*  </a>*/}
      {/*</div>*/}
      <img src={logoWhite} alt={'logo'} />
      <p>Copyright {new Date().getFullYear()} - Thomas Schauber</p>
      <span>
        Conception site web:
        <a href={'https://yoannlatzer.fr'} target={'_blank'} rel={'noreferrer'} >
          Yoann M.E. Latzer
        </a>
      </span>
    </footer>
  )
}
